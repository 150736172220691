/* eslint-disable require-jsdoc */

'use strict';

$(document).ready(function () {
    $('body').on('click', '.video-modal-trigger', function () {
        var theModal = $(this).data('target');
        var videoSRC = $(this).attr('data-video');
        var videoId = $(this).attr('data-video-id');
        var videoSRCauto = videoSRC + '?modestbranding=1&rel=0&showinfo=0&html5=1&autoplay=1&enablejsapi=1';
        var videoTitle = $(this).attr('title');
        window.videoTitle = videoTitle;

        // Move video modal outside of carousel duue to layout issue
        if ($(theModal).length) {
            if ($('body').find(theModal).hasClass('video-modal-transferred')) {
                // do nothing. just play the video
            } else {
                var videoTransferred = $('.video-modal-transferred');
                var id = videoTransferred.attr('id');
                if ($('.video-modal-trigger[data-target="#' + id + '"]').length) {
                    $('.video-modal-trigger[data-target="#' + id + '"]').closest('.pd-slide').find('.modal.video').remove();
                    $('.video-modal-trigger[data-target="#' + id + '"]').closest('.pd-slide').append(videoTransferred);
                    videoTransferred.removeClass('video-modal-transferred');
                }
                $('.video-modal-transferred').remove();
                $(theModal).addClass('video-modal-transferred')
            }
            $(theModal).appendTo('body');
        }

        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal).on('hidden.bs.modal', function () {
            $(theModal + ' iframe').attr('src', videoSRC);
        });
        new YT.Player('player', {
            events: {
                onStateChange: onPlayerStateChange
            }
        });
        new YT.Player(videoId, {
            events: {
                onStateChange: onPlayerStateChange
            }
        });

    });

    var tag = document.createElement('script');
    tag.id = 'iframe-demo';
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    function onPlayerStateChange(event) {
        if (event.data == 0) {
            dataLayer.push({
                event: 'videoInteraction',
                eventAction: 'complete',
                eventCategory: 'video',
                eventTitle: window.videoTitle
            });
        }
    }
});
